import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Layout from '../../components/v2/Layout';
import Button from '../../components/v2/Button';
import { IconButton, TextField, Checkbox, FormControl, FormLabel } from '@material-ui/core';

import { InputAdornment } from '@material-ui/core';
import { PersonRounded as PersonRoundedIcon, LockRounded as LockRoundedIcon, Phone as PhoneIcon, BrandingWatermarkRounded as IdIcon , WarningRounded as WarningRoundedIcon } from '@material-ui/icons';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import { postMembershipVerify } from '../../actions/partners';

const useStyles = makeStyles((theme) => ({
  
  cardLogin: {
    width: '100%',
    minHeight: '560px',
    borderRadius: '33px',
    boxShadow: '0px 5px 35px rgba(0, 0, 0, 0.25)',
    background: 'linear-gradient(135deg, rgba(255,255,255,0.7) 0%,  rgba(255,255,255,0.7) 18%,  rgba(208,241,255,0.7) 145%)',
    display:'flex',
    flexDirection: 'column',
    justifyContent:'center',
    animation: '$card-show 0.75s ease 1',
    [theme.breakpoints.down('lg')]: {
      minWidth: '512px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      borderRadius: '25px',
    },
    [theme.breakpoints.down(350)]: {
      minWidth: '100%',
      borderRadius: '20px',
    },
  },
  cardPermissionDenied: {
    width: '100%',
    minHeight: '450px',
    borderRadius: '33px',
    boxShadow: '0px 5px 35px rgba(0, 0, 0, 0.25)',
    background: 'linear-gradient(135deg, rgba(255,255,255,0.7) 0%,  rgba(255,255,255,0.7) 18%,  rgba(208,241,255,0.7) 145%)',
    display:'flex',
    flexDirection: 'column',
    justifyContent:'center',
    animation: '$card-show 0.75s ease 1',
    [theme.breakpoints.down('lg')]: {
      minWidth: '450px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      borderRadius: '25px',
    },
    [theme.breakpoints.down(350)]: {
      minWidth: '100%',
      borderRadius: '20px',
    },
  },
  permissionContainer: {
    position: 'relative',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    marginBottom: '15px',
    [theme.breakpoints.down(500)]: {
      width: '90%', 
    },
  },
  cardContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '25px 0 25px 0',
  },
  flexCenter:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  titleContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '35px',
    [theme.breakpoints.down(500)]: {
      width: '90%', 
    },
  },
  textCompany: {
    fontFamily: 'Prompt',
    fontSize: '35px',
    fontWeight: 500,
    textAlign: 'left',
    lineHeight: 1.3,
    [theme.breakpoints.down('xs')]: {
      fontSize: '33px', 
    },
    [theme.breakpoints.down(500)]: {
      fontSize: '29px', 
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '22px', 
    },
    [theme.breakpoints.down(300)]: {
      fontSize: '20px', 
    },
  },
  uppertitleContainer:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems:'center',
  },
  categorySign:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems:'center',
    minWidth: '95px', height: '25px',
    borderRadius: '15px',
    backgroundColor: 'rgb(37,55,69)',
    padding:'0 15px 0 5px',
    [theme.breakpoints.down(500)]: {
      height: '15px',
      justifyContent: 'left',
      minWidth: '50px',
      padding:'0 7px 0 5px',
    },
  },
  categorySignHandle:{
    backgroundColor: 'rgb(255,255,255)',
    width: '15px', height: '15px',
    borderRadius: '15px',
    marginRight: '5px',
    [theme.breakpoints.down(500)]: {
      width: '10px', height: '10px',
      marginRight: '5px',
    },
  },
  categorySignText:{
    fontFamily: 'Prompt',
    fontSize: '10px',
    fontWeight: 600,
    color: 'rgb(255,255,255)',
    [theme.breakpoints.down(500)]: {
      fontSize: '8px',
    },
  },
  subtitleContainer:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop:'-8px',
    height:'25px',
  },
  subtitleText:{
    fontFamily: 'Prompt',
    fontSize: '12px',
    fontWeight: 500,
    color: 'rgb(135,135,135)',
    [theme.breakpoints.down(500)]: {
      fontSize: '10px',
    },
  },
  logoImg:{
    width: 'auto',
    height: '25px',
    objectFit: 'cover',
    [theme.breakpoints.down(500)]: {
      height: '20px',
    },
  },
  inputContainer:{
    position: 'relative',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    marginBottom: '15px',
    [theme.breakpoints.down(500)]: {
      width: '90%', 
    },
  },
  checkBoxContainer:{
    position: 'relative',
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    alignItems: 'center',
    marginBottom: '15px',
    [theme.breakpoints.down(500)]: {
      width: '90%', 
    },
  },
  labelTextField: {
    fontFamily: 'Prompt',
    fontSize: '14px',
    fontWeight: 500,
    width: '80%',
    textAlign: 'left',
    [theme.breakpoints.down(500)]: {
      fontSize: '12px',
    },
  },
  inputTextField:{
    fontFamily: 'Prompt',
    fontSize: '14px',
    fontWeight: 400,
    backgroundColor: 'rgb(255,255,255)',
    borderRadius: '8px',
    [theme.breakpoints.down(500)]: {
      fontSize: '12px',
    },
  },
  helperTextShow: {
    fontFamily: 'Prompt',
    fontSize: '12px',
    fontWeight: 400,
    height: '25px',
    [theme.breakpoints.down(500)]: {
      fontSize: '10px',
    },
    animation: '$helper-text-show 0.5s ease 1',
    animationFillMode: 'forwards',
  },
  helperTextDisappear: {
    fontFamily: 'Prompt',
    fontSize: '12px',
    fontWeight: 400,
    height: '0px',
    transition: 'all 0.5s ease',
    [theme.breakpoints.down(500)]: {
      fontSize: '10px',
    },
    animation: '$helper-text-disappear 0.5s ease 1',
    animationFillMode: 'forwards',
  },
  NoPermissionImg: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    animation: '$card-show 1s ease 1',
  },
  verifyButton: {
    width: '80%',
    fontFamily: 'Prompt',
    textTransform: 'capitalize',
    fontWeight: 600,
    transition: 'all 0.5s ease , box-shadow 0.5s ease',
    color: 'rgb(255,255,255)',
    boxShadow: 'inset 0 0 0 0 rgba(70,150,251,1)',
    background: 'linear-gradient(90deg, rgba(35,170,251,1) 33%, rgba(63,182,253,1) 55%, rgba(36,140,203,1) 100%)',
    [theme.breakpoints.down(500)]: {
      width: '90%',
      fontSize: '15px', 
    },
    '&:hover': {
      scale: 1.025,
      boxShadow: 'inset 700px 0 0 0 rgba(70,150,251,1)',
    
    }
  },
  verifyButtonDisable:{
    width: '80%',
    fontFamily: 'Prompt',
    textTransform: 'capitalize',
    fontWeight: 600,
    transition: 'all 0.5s ease',
    color: 'rgb(184,184,184)',
    background: 'linear-gradient(90deg, rgba(223,226,227,1) 33%, rgba(223,223,223,1) 55%, rgba(210,210,210,1) 100%)',
    [theme.breakpoints.down(500)]: {
      width: '90%', 
    }
  },
  verifyButtonText:{
    fontSize: '18px',
    [theme.breakpoints.down(500)]: {
      fontSize: '15px',
    },
  },
  errorMessage:{
    fontFamily: 'Prompt',
    fontSize: '14px',
    fontWeight: 500,
    color: '#f44336',
    width: '80%',
    minHeight: '22px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    marginBottom: '10px',
    [theme.breakpoints.down(500)]: {
      width: '90%', 
      fontSize: '12px',
      lineHeight: 1.1,
    },
    [theme.breakpoints.down(350)]: {
      fontSize: '10px',
    },
  },  
  titleTextPermission:{
    fontSize:'45px', 
    fontFamily:'QuickSand', 
    fontWeight:700,
    margin: '15px 0 10px 0',
    [theme.breakpoints.down('xs')]: {
      fontSize: '45px', 
    },
    [theme.breakpoints.down(500)]: {
      fontSize: '42px', 
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '38px', 
    },
    [theme.breakpoints.down(300)]: {
      fontSize: '35px', 
    },
  },
  textPermissionThai:{
    fontFamily: 'Prompt',
    fontSize: '20px',
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: 1.3,
    margin: '5px 0 10px 0',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px', 
    },
    [theme.breakpoints.down(500)]: {
      fontSize: '18px', 
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '16px', 
    },
    [theme.breakpoints.down(300)]: {
      fontSize: '14px', 
    },
  },
  textPermissionEng:{
    fontFamily: "QuickSand",
    fontSize: '25px',
    fontWeight: 600,
    textAlign: 'center',
    lineHeight: 1.1,
    margin: '10px 0 5px 0',
    [theme.breakpoints.down('xs')]: {
      fontSize: '25px', 
    },
    [theme.breakpoints.down(500)]: {
      fontSize: '22px', 
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '20px', 
    },
    [theme.breakpoints.down(300)]: {
      fontSize: '18px', 
    },
  },
  errorMessageIcon:{
    marginRight: '5px'
  },
  checkBox:{
    '&.MuiSvgIcon-root': { 
      fontSize: '50px',
    },
    '&.Mui-checked': {
      color: "rgba(35,170,251,1)",
    },
  },
  checkBoxLabel:{
    fontFamily: 'Prompt',
    fontSize: '15px',
    color: 'black'
  },
  '@keyframes card-show': {
    '0%': {
      scale: '0.5',
      opacity: '0.0',
    },
    '100%': {
      scale: '1.0',
      opacity: '1.0',
    },
  },
  '@keyframes helper-text-show': {
    '0%': {
      height: '0px',
      opacity: '0.0',
    },
    '100%': {
      height: '25px',
      opacity: '1.0',
    },
  },
  '@keyframes helper-text-disappear': {
    '0%': {
      height: '25px',
      opacity: '1.0',
    },
    '100%': {
      height: '0px',
      opacity: '0.0',
    },
  },
  
}));


export default function Login({ location }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { prevPage, requireField, corporate } = useSelector(state => state.partners); 
  const [errorResponse, setErrorResponse] = React.useState(null);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = (index) => {

    const isShowPassword = fieldAllState[index].is_input_show;

    setFieldAllState(prevState => {
      var newState = [...prevState];
      newState[index] = {
        ...newState[index], 
        'is_input_show': !isShowPassword,
      };
      return newState;
    })
  }

  const content = {
    'header-p1': 'Verify',
    'company_name': corporate?.name? corporate.name:'Company' ,
    'category': 'for employee',
    'mor_online_logo': '../../assets/images/LogoMorwithLetter.png',
    'no_permission_img': '../../assets/images/NoPermission.png',
    'no_permission_desc_en': "you don't have permission to access this page.",
    'no_permission_desc_th': "ขออภัยคุณไม่มีสิทธิเข้าถึงหน้านี้"
  };
  
  const onChangeActionSelector = (field_type, index, value) => {

    switch (field_type) {
      case 'email':
        handleEmailChange(index, value); break;
      case 'phone':
        handlePhoneNumberChange(index, value); break;
      case 'string':
        handleStrTypeFieldChange(index, value); break;
      case 'integer':
        handleIntTypeFieldChange(index, value); break;
      case 'boolean':
        handleBoolTypeFieldChange(index, value); break;
      case 'password':
        handlePasswordChange(index, value); break;
      default:
        handleStrTypeFieldChange(index, value); break;
    }
  }

  const placeHolderSelector = (name,type) => {
    const fieldType = type.toLowerCase();
    const fieldName = name.toLowerCase().replace(/ /g, '');

    if(fieldType === "email") return "Enter your Email";
    else if(fieldType === "phone") return "Enter your phone e.g.(999-999-9999)";
    else if(fieldType === "password") return "Enter your Password";
    else if(fieldType === "boolean") return "";
    else if(fieldName.includes("employee")) return "Enter your Employee ID";
    else if(fieldName.includes("question")) return "Answer the Security Question";
    else if(fieldName === "corporate_id") return "Enter your Corporate ID";
    else if(fieldName === "first_name") return "Enter your First Name";
    else if(fieldName === "last_name") return "Enter your Last Name";
    else if(fieldName === "position") return "Specify your Job Position";
    else if(fieldName === "id") return "Enter your ID";
    else return "Fill in this field";
  }

  const handleEmailChange = (index, value) => {
    var newSetEmail = value
    var errorText = ""

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if(!newSetEmail){
      errorText = "Require this field"
      var newSetIsEmailValid = false
    }
    else if (!emailRegex.test(value)) {
      errorText = "Invalid email format"
      var newSetIsEmailValid = false
    } 
    else {
      var newSetIsEmailValid = true
    }

    setFieldAllState(prevState => {
      var newState = [...prevState];
      newState[index] = {
        ...newState[index], 
        'is_field_valid': newSetIsEmailValid,
        'is_field_touched': true,
        'field_value_show': newSetEmail,
        'field_value_use': newSetEmail,
        'helper_text': errorText,
      };
      return newState;
    })
  };

  const handleStrTypeFieldChange = (index, value) => {
    var newSetStrTypeField = value
    var errorText = ""
    
    if (!value) {
      errorText = "Require this field"
      var newSetIsStrTypeFieldValid = false
    } else {
      errorText = ""
      var newSetIsStrTypeFieldValid = true
    }

    setFieldAllState(prevState => {
      var newState = [...prevState];
      newState[index] = {
        ...newState[index], 
        'is_field_valid': newSetIsStrTypeFieldValid,
        'is_field_touched': true,
        'field_value_show': newSetStrTypeField,
        'field_value_use': newSetStrTypeField,
        'helper_text': errorText,
      };
      return newState;
    })
  }

  const handleIntTypeFieldChange = (index, value) => {
    const cleanedIntType = value.replace(/\D/g, '')
    
    var errorText = ""
    
    if (!cleanedIntType) {
      errorText = "Require this field"
      var newSetIsIntTypeFieldValid = false
    } else {
      errorText = ""
      var newSetIsIntTypeFieldValid = true
    }

    setFieldAllState(prevState => {
      var newState = [...prevState];
      newState[index] = {
        ...newState[index], 
        'is_field_valid': newSetIsIntTypeFieldValid,
        'is_field_touched': true,
        'field_value_show': cleanedIntType,
        'field_value_use': cleanedIntType,
        'helper_text': errorText,
      };
      return newState;
    })
  }


  const handleBoolTypeFieldChange = (index, value) => {

    setFieldAllState(prevState => {
      var newState = [...prevState];
      newState[index] = {
        ...newState[index], 
        'field_value_show': value,
        'field_value_use': value,
      };
      return newState;
    })
  }

  const handlePasswordChange = (index, value) =>{
    var errorText = ""

    if (!value) {
      var newSetIsPasswordFieldValid = false;
      errorText = "Require this field";
    }
    else{
      var newSetIsPasswordFieldValid = true;
      errorText = ""
    }

    setFieldAllState(prevState => {
      var newState = [...prevState]; 
      newState[index] = {
        ...newState[index], 
        'is_field_valid': newSetIsPasswordFieldValid,
        'is_field_touched': true,
        'field_value_show': value,
        'field_value_use': value,
        'helper_text': errorText,
      };
      return newState;
    })
  }

  const handlePhoneNumberChange = (index, value) => {
    const cleanedPhoneNumber = value.replace(/\D/g, '').slice(0, 10); //auto remove all non-digit char
    var errorText = ""

    const formattedPhoneNumber = cleanedPhoneNumber.replace(
      /(\d{3})(\d{0,3})(\d{0,4})/, (_, p1, p2, p3) => {
      let result = p1;
      if (p2) result += `-${p2}`;
      if (p3) result += `-${p3}`;
      return result;
    });
    
    var newSetPhoneNumberShow = formattedPhoneNumber;
    var newSetPhoneNumber = formattedPhoneNumber.replace(/\D/g, '');
    
    const phoneRegex = /^(?:(?:\+\d{1,3}\s?)?1\s?)?(?:\(\d{3}\)|\d{3})(?:[-\s]?)\d{3}(?:[-\s]?)\d{4}$/;

    if (!cleanedPhoneNumber){
      errorText = "Require this field"
    } else if (!phoneRegex.test(formattedPhoneNumber)) {
      errorText = "Invalid email format"
      var newSetIsPhoneNumberValid = false
    } else {
      var newSetIsPhoneNumberValid = true
    }

    setFieldAllState(prevState => {
      var newState = [...prevState];
      newState[index] = {
        ...newState[index], 
        'is_field_valid': newSetIsPhoneNumberValid,
        'is_field_touched': true,
        'field_value_show': newSetPhoneNumberShow,
        'field_value_use': newSetPhoneNumber,
        'helper_text': errorText,
      };
      return newState;
    })
  }

  const handleVerify = async () =>{
    setErrorResponse(null) // reset ErrorResponse
    try {

      const payload = {}; 
      fieldAllState.forEach((field)=>{
          payload[field.field_name] = field.field_value_use ;
      })

      await dispatch(postMembershipVerify(payload))
      navigate(prevPage);
    }
    catch(e) { 
      const payload = {}; 
      fieldAllState.forEach((field)=>{
        payload[field.field_name] = field.field_value_use ;
      })

      setErrorResponse(e)
    }
  }

  const handleGoBack = () =>{
    navigate('/')
  }

  const fieldListPresets = [
    {
      'field_type': 'email',
      'field_name': 'email',
      'label': 'Email',
      'place_holder_text': '' ,
      'helper_text' : 'Invalid email format',
      'is_field_valid': false,
      'is_field_touched': false,
      'icon': <PersonRoundedIcon/>,
      'field_value_show': '',
      'field_value_use': '',
      'is_input_show': true,
    },
    {
      'field_type': 'string',
      'field_name': 'employee_id',
      'label': 'Employee ID',
      'place_holder_text': 'Enter your employee_id',
      'helper_text' : 'Require this field',
      'is_field_valid': false,
      'is_field_touched': false,
      'icon': <IdIcon/>,
      'field_value_show': '',
      'field_value_use': '',
      'is_input_show': true,
    },
    {
      'field_type': 'integer',
      'field_name': 'numcode',
      'label': 'code number',
      'place_holder_text': 'Enter your number code',
      'helper_text' : 'Require this field',
      'is_field_valid': false,
      'is_field_touched': false,
      'icon': <IdIcon/>,
      'field_value_show': '',
      'field_value_use': '',
      'is_input_show': true,
    },
    {
      'field_type': 'phone',
      'field_name': 'phone',
      'label': 'Phone Number',
      'place_holder_text': '',
      'helper_text' : 'Invalid phone number format',
      'is_field_valid': false,
      'is_field_touched': false,
      'icon': <PhoneIcon/>,
      'field_value_show': '',
      'field_value_use': '',
      'is_input_show': true,
    },
    {
      'field_type': 'boolean',
      'field_name': 'checkbox',
      'label': 'Checkbox',
      'place_holder_text': ' ',
      'helper_text' : ' ',
      'is_field_valid': true,
      'is_field_touched': true,
      'icon': <PhoneIcon/>,
      'field_value_show': false,
      'field_value_use': false,
      'is_input_show': true,
    },
    {
      'field_type': 'password',
      'field_name': 'password',
      'label': 'Password',
      'place_holder_text': 'Enter your password',
      'helper_text' : 'Require this field',
      'is_field_valid': false,
      'is_field_touched': false,
      'icon': <LockRoundedIcon/>,
      'field_value_show': '',
      'field_value_use': '',
      'is_input_show': false,
    },
  ]

  const finalFieldList = requireField ?
    requireField.map((required) => {
      const presetFieldMatch = fieldListPresets.find((fieldPreset) => fieldPreset.field_type === required.type );
      
      if(presetFieldMatch){
        return {
          ...presetFieldMatch, 
          field_name: required.name,
          label: required.label,
          place_holder_text: placeHolderSelector(required.name,required.type)
        };
      }
    }) 
    : []

  const [fieldAllState,setFieldAllState] = React.useState(
    finalFieldList
  );

  const isButtonDisabled = fieldAllState.some((field) => !field.is_field_valid);

  return (
    <Layout
      bg="bg5"
      title={content['header-p1']}
    >
      <div className={classes.flexCenter} style={{ height: '100vh' }}>
        <Grid container spacing={0} 
          justifyContent='center' 
          alignItems='center'
        >
          
          <Grid item xs={12} sm={5} md={5} xl={5} className={classes.flexCenter} >

            {requireField ? 
              <Card variant="outlined" className={classes.cardLogin}>
                  <CardContent style={{height:'100%'}}>
                    <Box textAlign="center" className={classes.cardContent}>
                      <div className={classes.titleContainer}>
                        <div className={classes.uppertitleContainer}>
                          <div className={classes.categorySign}>
                            <div className={classes.categorySignHandle}></div>
                            <Typography className={classes.categorySignText} variant='h6' component='h1' color='textPrimary'>
                              {content['category']}
                            </Typography>
                          </div>
                        </div>
                        <Typography className={classes.textCompany} variant='h6' component='h1' color='textPrimary'>
                          {content['company_name']}
                        </Typography>
                        <div className={classes.subtitleContainer}>
                          <p className={classes.subtitleText}>powered by </p>
                          <img className={classes.logoImg} src={require(`${'../../assets/images/LogoMorwithLetter.png'}`)} alt='' />
                        </div>
                      </div>

                      { 
                        fieldAllState.map((field, index) => {
                          return(
                            <div className={field.field_type !== 'boolean' ? classes.inputContainer : classes.checkBoxContainer} key={index}>

                              {field.field_type !== 'boolean' ?
                                <>
                                  <Typography className={classes.labelTextField} variant='h6' component='h1' color='textPrimary'>
                                    {field.label}
                                  </Typography>
                                  <TextField label="" variant="outlined" 
                                    placeholder={field.place_holder_text}
                                    type={field.field_type !== 'password'? 'text' : field.is_input_show ? 'text' : 'password'}
                                    required
                                    value={field.field_value_show}
                                    onChange={e => onChangeActionSelector(field.field_type,index, e.target.value)}
                                    error={!field.is_field_valid && (field.field_value_show !== '' || field.is_field_touched)}
                                    helperText={!field.is_field_valid && (field.field_value_show !== '' || field.is_field_touched) ? field.helper_text : ' '}
                                    InputProps={{
                                      className: classes.inputTextField,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          {field.icon}
                                        </InputAdornment>
                                      ),
                                      endAdornment: field.field_type === 'password' ?
                                      (
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={()=>handleClickShowPassword(index)}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                          >
                                            {field.is_input_show ? <VisibilityOff /> : <Visibility />}
                                          </IconButton>
                                        </InputAdornment>
                                      )
                                      : null
                                    }}
                                    FormHelperTextProps={{
                                      className: !field.is_field_valid && (field.field_value_show !== '' || field.is_field_touched) ?
                                        (classes.helperTextShow) : 
                                        (classes.helperTextDisappear)
                                    }}
                                    
                                  />
                                </>
                              : 
                                <>
                                  <FormLabel className={classes.checkBoxLabel}>
                                    <span>{field.label}</span>
                                  </FormLabel>
                                  <Checkbox
                                    checked={field.field_value_show}
                                    onChange={e => onChangeActionSelector(field.field_type,index, e.target.checked)}
                                    className={classes.checkBox}
                                  /> 
                               </>
                              }
                            </div>
                          );
                        })
                        
                      }
                      
                      {
                        errorResponse != null?
                        <div className={classes.errorMessage}>
                          <WarningRoundedIcon className={classes.errorMessageIcon}/>
                            {errorResponse.message.detail} 
                        </div>
                        : <div className={classes.errorMessage}></div>
                      }
                      <Button 
                        className={ isButtonDisabled ? classes.verifyButtonDisable : classes.verifyButton}
                        disabled={ isButtonDisabled ? true : false}
                        onClick={()=>handleVerify()}
                      >
                          <span className={classes.verifyButtonText}>{'Verify'}</span> 
                      </Button>
                      
                    </Box>
                  </CardContent>
              </Card>
              : 
              <Card variant="outlined" className={classes.cardLogin}>
                <CardContent>
                  <Box textAlign="center" className={classes.cardContent}>
                    <div className={classes.permissionContainer}>
                      <h1 className={classes.titleTextPermission}>
                        {"Sorry :("}
                      </h1>
                      <img className={classes.NoPermissionImg} src={require(`${'../../assets/images/NoPermission.png'}`)} alt='' />
                      <Typography className={classes.textPermissionEng} variant='h6' component='h1' color='textPrimary'>
                            {content['no_permission_desc_en']}
                      </Typography>
                      <Typography className={classes.textPermissionThai} variant='h6' component='p' color='textPrimary'>
                            {content['no_permission_desc_th']}
                      </Typography>
                    </div>

                    <Button 
                        className={ classes.verifyButton}
                        onClick={()=>handleGoBack()}
                      >
                        <span className={classes.verifyButtonText}>{'กลับไปหน้าแรก'}</span> 
                    </Button>

                  </Box>
                </CardContent>
              </Card> 
            }
          </Grid>
              
        </Grid>
      </div>
    </Layout>
  );
}